import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import * as pick from "lodash/pick";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  Input,
  Select,
  InputOnlyNumbers,
  Autocomplete,
  DatePicker,
} from "../Shared/mui-formik-inputs";
import { OrderSchema } from "constants/validation-schemas";
import { OrderFormAllowedFields } from "constants/forms-submit-allowed-fields";
import { PATHS } from "util/appConstants";
import FormCancelSaveButton from "components/Shared/FormCancelSaveButtons";
import { formatCustomerLabel } from "util/orders/formatCustomerLabel";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";
import { sortOrder } from "util/customers/deliveryTimings";

const locales = {
  "en-us": "en",
  en: "en",
  de: "de",
};

const useStyles = makeStyles({
  _heading: {
    font: "normal normal normal 28px/40px Questrial",
    color: "#121212",
  },
  _icons: {
    color: "#ADADAD",
    fontSize: "35px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    margin: "0 16px 0 0px",
  },
  _save: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#6F9CEB",
    },
  },
  _close: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#525252",
    },
  },
  _subheading: {
    font: "normal normal 500 22px/32px Roboto",
    color: " #121212",
    marginTop: "44px",
  },
});
const OrderForm = ({
  initialValues,
  onSubmit,
  action,
  customerList,
  routes,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [departureOptions, setDepartureOptions] = useState([]);
  const [isSlotsAvailable, setIsSlotsAvailable] = useState(true);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const user = useSelector(selectUser);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: OrderSchema,
    initialValues: {
      customer_id: "",
      description: "",
      number: "",
      packages: 0,
      departure: null,
      departure_date: initialValues?.departure_date
        ? moment(
            `${initialValues?.departure_date} 12:00:00`,
            "YYYY-MM-DD HH:mm:ss"
          )
            .utc()
            .toDate()
        : moment().utc().startOf("day").toDate(),
      remarks: "",
      express: false,
      ...initialValues,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onSubmit({
          ...pick(values, OrderFormAllowedFields),
          departure_date: values.departure_date?.format
            ? values.departure_date.format("YYYY-MM-DD")
            : values.departure_date,
          remarks: values.remarks || null,
        });
      } catch (err) {
        setSubmitting(false);
      }
    },
  });
  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    setFieldValue,
    submitCount,
    isValid,
    isSubmitting,
  } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  const closeOrderHandler = () => {
    history.push(PATHS.orders.root);
  };
  // Adding label to view in order
  const customerListWithLabels = useMemo(
    () =>
      customerList?.map((customer) => ({
        ...customer,
        label: formatCustomerLabel(customer),
      })),
    [customerList]
  );

  const cancelButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  const lastElementRef = useRef(null);
  useEffect(() => {
    const handleTabPress = (e) => {
      if (e.key === "Tab") {
        if (
          lastElementRef.current &&
          lastElementRef.current.contains(document.activeElement)
        ) {
          e.preventDefault();
          if (cancelButtonRef.current) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            cancelButtonRef.current.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabPress);
    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []);
  console.log(errors);
  const updateDepartureOptions = (selectedCustomer, selectedDate) => {
    if (selectedCustomer) {
      const isFutureDate = moment(selectedDate).isAfter(moment(), "day");
      if (isFutureDate) {
        // Show all slots for future dates
        const availableDeliveryTimings = selectedCustomer.Tour.delivery_timings;
        setIsSlotsAvailable(selectedCustomer.Tour.delivery_timings.length);
        setDepartureOptions(
          availableDeliveryTimings?.map((timing) => ({
            label: t(timing),
            value: timing,
          })) || []
        );
      } else {
        // Check available slots for current date
        const selectedRoutes = routes?.filter(
          (route) => route.Tour.id === selectedCustomer.Tour.id
        );
        const occupiedDeliveryTimings = selectedRoutes?.map(
          (selectedRoute) => selectedRoute.pathway[0].Orders[0].departure
        );
        const availableDeliveryTimings =
          selectedCustomer.Tour.delivery_timings?.filter(
            (deliveryTiming) =>
              !occupiedDeliveryTimings?.includes(deliveryTiming)
          );
        setIsSlotsAvailable(!!availableDeliveryTimings.length);
        const sortedDeliveryTimings = availableDeliveryTimings.sort((a, b) => {
          return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        });
        setDepartureOptions(
          sortedDeliveryTimings?.map((timing) => ({
            label: t(timing),
            value: timing,
          })) || []
        );
      }
    }
  };

  useEffect(() => {
    if (values.customer_id) {
      const selectedCustomer = customerListWithLabels.find(
        (c) => c.id === values.customer_id
      );
      setSelectCustomer(selectedCustomer);
      setFieldValue("express", selectedCustomer?.priority);
      updateDepartureOptions(selectedCustomer, values.departure_date);
    }
  }, [values.customer_id, values.departure_date, customerListWithLabels, t]);

  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale={locales[i18n.language.toLowerCase()]}
    >
      <Box height="100%">
        <Box display="flex" mb={4}>
          <Box flex={2}>
            <Typography className={classes._heading} variant="h4">
              {action === "ADD" ? t("New Order") : t("Edit Order")}
            </Typography>
          </Box>
          <Box flex={2} textAlign="right" position="relative">
            <Box position="absolute" right={0}>
              <FormCancelSaveButton
                disabled={!isValid || isSubmitting}
                onCancel={closeOrderHandler}
                onSave={handleSubmit}
                ref={{ cancelRef: cancelButtonRef, saveRef: saveButtonRef }}
              />
            </Box>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Autocomplete
              onBlur={handleBlur}
              name="customer_id"
              label={t("Customer")}
              errors={errors}
              value={values.customer_id}
              settings={{
                disableClearable: true,
                valueProp: "id",
                labelProp: "label",
              }}
              onChange={(selected) => {
                setFieldValue("customer_id", selected.id);
              }}
              options={customerListWithLabels.filter((c) => c.active)}
              required
              disabled={user?.permissions?.orders?.disable_customers_load}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/*<Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("Description")}
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            errors={errors}
          />
          </Grid>*/}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              label={t("Order Number")}
              name="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.number}
              errors={errors}
              inputProps={{
                maxLength: 20,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <InputOnlyNumbers
              label={t("Packages")}
              name="packages"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.packages}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DatePicker
              onChange={(date) => {
                if (date && date.isValid()) {
                  setFieldValue("departure_date", date);
                }

                if (date === null) {
                  setFieldValue("departure_date", null);
                }
              }}
              name="departure_date"
              value={values.departure_date}
              errors={errors}
              label="Departure date"
              required
              disablePast
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Select
              label={
                isSlotsAvailable
                  ? t("Departure") + " *"
                  : t("No Departures Available")
              }
              name="departure"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.departure}
              errors={errors}
              options={departureOptions}
              disabled={departureOptions.length === 0}
              InputLabelProps={{
                className: isSlotsAvailable ? "" : "red-label",
              }}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Select
              label={t("Express Order") + " *"}
              name="express"
              onChange={(event) =>
                setFieldValue("express", event.target.value === "true")
              }
              onBlur={handleBlur}
              value={
                values.express || selectCustomer?.priority ? "true" : "false"
              }
              errors={errors}
              options={[
                { label: t("Yes"), value: "true" },
                { label: t("No"), value: "false" },
              ]}
              disabled={selectCustomer?.priority}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div ref={lastElementRef}>
              <Input
                label={t("Remarks")}
                name="remarks"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.remarks}
                errors={errors}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  );
};
export default OrderForm;
