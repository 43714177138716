import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { PATHS, DEPOSIT_AGREEMENTS } from "util/appConstants";
import {
  selectCustomer,
  selectCustomerStatus,
  getCustomer,
} from "redux/slices/customerSlice";
import Navbar from "components/Navbar";
import CustomersNavbar from "components/Masterbar/CustomersBar";
import DarkLayout from "components/Shared/DarkLayout";
import { selectUser } from "redux/slices/userSlice";
import { usePermission } from "../../hooks/usePermission";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  _heading: {
    color: "#F5F5F5",
    font: "normal normal normal 28px/40px Questrial",
  },
  _edit: {
    color: "#6F9CEB",
    width: "22px",
    height: "22px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.3)",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _editbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  _infoheading: {
    color: "#F5F5F5",
    font: "normal normal medium 22px/32px Roboto",
    marginTop: "44px",
  },
  _head: {
    color: "#F5F5F5",
    opacity: "0.6",
    font: "normal normal normal 12px/24px Roboto",
    marginRight: "32px",
  },
  _basicdetailhead: {
    display: "flex",
    marginTop: "41px",
  },
  _basicdetail: {
    display: "flex",
  },
  _detail: {
    color: "#F5F5F5",
    marginRight: "32px",
    font: "normal normal normal 18px/24px Roboto",
  },
  _name: {
    letterSpacing: "0px",
  },
  _margintop80: {
    marginTop: "80px",
  },
  _buttonbox: {
    display: "flex",
    margin: "0 16px",
  },
  _edittext: {
    width: "24px",
    height: "16px",
    color: "#6F9CEB",
    font: "normal normal normal 14px / 20px Roboto",
    padding: "4px 8px",
    display: "none",
    position: "absolute",
    marginLeft: "20px",
    transition: "all 0.3s ease-in-out",
  },
});

const CustomerDetail = ({ type }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const loading = useSelector(selectCustomerStatus);
  const customer = useSelector(selectCustomer);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (id) {
      dispatch(getCustomer(id));
    }
  }, [dispatch, id]);

  const editHandler = () => {
    if (type === "edit") {
      history.push(PATHS.customers.edit.replace(":id", id));
    } else {
      if (user?.supplier_id === customer?.supplier_id) {
        history.push(PATHS.customers.edit.replace(":id", id));
      } else {
        history.push(PATHS.customers.addWithExistingData.replace(":id", id));
      }
    }
  };

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <DarkLayout doublebar loading={loading || !customer}>
        <div className={classes._editbox}>
          <Typography className={classes._heading} variant="h4">
            {customer?.name}
          </Typography>
          {(user?.permissions?.customersCreate ||
            user?.permissions?.customers?.create) && (
            <Box className={classes._buttonbox} component="div">
              <EditIcon onClick={editHandler} className={classes._edit} />
              <Typography
                component="span"
                className={clsx(classes._edittext, "edittag")}
              >
                {t("Edit")}
              </Typography>
            </Box>
          )}
        </div>
        {type === "edit" && user?.supplier_id !== customer?.supplier_id && (
          <Typography
            className={classes._infoheading}
            variant="p"
            style={{
              backgroundColor: "#6F9CEB",
              padding: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlined style={{ marginRight: "8px" }} />{" "}
            {t(
              "Customer ID is missing. Please edit the customer and add the customer ID."
            )}
          </Typography>
        )}
        {type === "edit" &&
          user?.supplier_id === customer?.supplier_id &&
          !customer?.active && (
            <Typography
              className={classes._infoheading}
              variant="p"
              style={{
                backgroundColor: "#6F9CEB",
                padding: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoOutlined style={{ marginRight: "8px" }} />{" "}
              {t(
                "The customer is inactive. If necessary, please edit and activate."
              )}
            </Typography>
          )}
        <Typography className={classes._infoheading} variant="h5">
          {t("Basic Data")}
        </Typography>
        <div className={classes._basicdetailhead}>
          {user?.supplier_id === customer?.supplier_id && (
            <div>
              <Typography
                className={clsx(classes._head, classes._width11)}
                variant="h6"
              >
                {t("ID")}
              </Typography>
              <Typography
                className={clsx(classes._detail, classes._width11)}
                variant="h6"
              >
                {customer?.number}
              </Typography>
            </div>
          )}
          <div>
            <Typography
              className={clsx(classes._head, classes._name)}
              variant="h6"
            >
              {t("Name")}
            </Typography>
            <Typography
              className={clsx(classes._name, classes._detail)}
              variant="h6"
            >
              {customer?.name}
            </Typography>
          </div>
          {usePermission("customers.deactivate") && (
            <div>
              <Typography className={classes._head} variant="h6">
                {t("Active")}
              </Typography>
              <Typography className={classes._detail} variant="h6">
                {customer?.active ? <CheckIcon /> : <XIcon />}
              </Typography>
            </div>
          )}
          {usePermission("customers.archive") && (
            <div>
              <Typography className={classes._head} variant="h6">
                {t("Archive")}
              </Typography>
              <Typography className={classes._detail} variant="h6">
                {customer?.duplicate ? <CheckIcon /> : <XIcon />}
              </Typography>
            </div>
          )}
        </div>
        {/** */}
        <div className={classes._basicdetailhead}>
          <div>
            <Typography
              className={clsx(classes._head, classes._width11)}
              variant="h6"
            >
              {t("Address")}
            </Typography>
            <Typography
              className={clsx(classes._detail, classes._width11)}
              variant="h6"
            >
              {customer?.formatted_address
                ? customer.formatted_address
                : `${customer?.street} ${customer?.street_number}, ${customer?.zipcode}, ${customer?.city}, ${customer?.country}`}
            </Typography>
          </div>
          {user?.permissions?.customers?.show_coordinates && (
            <div>
              <Typography className={classes._head} variant="h6">
                {t("Geolocation")}
              </Typography>
              <Typography className={classes._detail} variant="h6">
                {customer?.latitude}, {customer?.longitude}
              </Typography>
            </div>
          )}
        </div>
        {/** */}
        {!user?.permissions?.customersHideLocationRelatedFields &&
          !user?.permissions?.customers?.hide_location_fields && (
            <>
              <Typography
                className={clsx(classes._infoheading, classes._margintop80)}
                variant="h5"
              >
                {t("Contact")}
              </Typography>
              <div className={classes._basicdetailhead}>
                <div>
                  <Typography className={clsx(classes._head)} variant="h6">
                    {t("Salutation")}
                  </Typography>
                  <Typography className={clsx(classes._detail)} variant="h6">
                    {t(customer?.contact_salutation)}
                  </Typography>
                </div>
                <div>
                  <Typography
                    className={clsx(classes._head, classes._name)}
                    variant="h6"
                  >
                    {t("First Name")}
                  </Typography>
                  <Typography
                    className={clsx(classes._name, classes._detail)}
                    variant="h6"
                  >
                    {customer?.contact_name}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes._head} variant="h6">
                    {t("Last Name")}
                  </Typography>
                  <Typography className={classes._detail} variant="h6">
                    {customer?.contact_surname}
                  </Typography>
                </div>
              </div>
            </>
          )}

        {/*** */}
        <div className={classes._basicdetailhead}>
          <div>
            <Typography className={clsx(classes._head)} variant="h6">
              {t("Phone")}
            </Typography>
            <Typography className={clsx(classes._detail)} variant="h6">
              {customer?.phone || "–"}
            </Typography>
          </div>
        </div>
        {/*** */}

        <div div className={classes._basicdetailhead}>
          <div>
            <Typography className={clsx(classes._head)} variant="h6">
              {t("E-Mail")}
            </Typography>
            <Typography className={clsx(classes._detail)} variant="h6">
              {customer?.email}
            </Typography>
          </div>
          <div>
            <Typography className={clsx(classes._head)} variant="h6">
              {t("Notify")}
            </Typography>
            <Typography className={clsx(classes._detail)} variant="h6">
              {customer?.email_notifications ? t("Yes") : t("No")}
            </Typography>
          </div>
        </div>
        {/*** */}

        <Typography
          className={clsx(classes._infoheading, classes._margintop80)}
          variant="h5"
        >
          {t("Tour")}
        </Typography>
        <div className={classes._basicdetailhead}>
          <div>
            <Typography className={clsx(classes._head)} variant="h6">
              {t("ID")}
            </Typography>
            <Typography className={clsx(classes._detail)} variant="h6">
              {customer?.tour_id}
            </Typography>
          </div>
          <div>
            <Typography
              className={clsx(classes._head, classes._name)}
              variant="h6"
            >
              {t("Name")}
            </Typography>
            <Typography
              className={clsx(classes._name, classes._detail)}
              variant="h6"
            >
              {customer?.Tour.name}
            </Typography>
          </div>
          <div>
            <Typography className={clsx(classes._head)} variant="h6">
              {t("Express Customer")}
            </Typography>
            <Typography className={clsx(classes._detail)} variant="h6">
              {customer?.priority ? t("Yes") : t("No")}
            </Typography>
          </div>
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Automatic tour")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {!customer?.tour_manually_assigned ? <CheckIcon /> : <XIcon />}
            </Typography>
          </div>
        </div>
        {/*** */}

        {!user?.permissions?.customersHideLocationRelatedFields &&
          !user?.permissions?.customers?.hide_location_fields && (
            <div className={classes._basicdetailhead}>
              {user?.permissions?.customers?.deposit_agreement && (
                <div>
                  <Typography className={clsx(classes._head)} variant="h6">
                    {t("Deposit agreement")}
                  </Typography>
                  <Typography className={clsx(classes._detail)} variant="h6">
                    {t(DEPOSIT_AGREEMENTS[customer?.deposit_agreement])}
                  </Typography>
                </div>
              )}

              {customer?.deposit_agreement === "KEY_BOX" && (
                <div>
                  <Typography className={clsx(classes._head)} variant="h6">
                    {t("Code")}
                  </Typography>
                  <Typography className={clsx(classes._detail)} variant="h6">
                    {customer?.keybox_code}
                  </Typography>
                </div>
              )}
            </div>
          )}
      </DarkLayout>
    </>
  );
};

export default CustomerDetail;
