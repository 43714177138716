import { useState, useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import Box from "@material-ui/core/Box";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { InputAdornment } from "@material-ui/core";
import PlaceOutlinedIcon from "@material-ui/icons/PlaceOutlined";

const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;
const MAPBOX_MAP_STYLE = process.env.REACT_APP_MAPBOX_WHITE_MAP_STYLE;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  fill: "#6F9CEB",
  stroke: "none",
};

function Pin(props) {
  const { size = 20 } = props;

  return (
    <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
      <path d={ICON} />
    </svg>
  );
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const SelectGeoCoordinates = ({
  onChange,
  latitude,
  longitude,
  initialInputValue,
}) => {
  const [marker, setMarker] = useState({
    latitude,
    longitude,
  });
  const [viewport, setViewport] = useState({
    latitude,
    longitude,
    zoom: 8,
    bearing: 0,
    pitch: 0,
  });
  const [sessionToken, setSessionToken] = useState(null);
  const [inputValue, setInputValue] = useState(initialInputValue || "");
  const [places, setPlaces] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const debouncedInput = useDebounce(inputValue, 500);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (
      isLoaded &&
      window.google &&
      window.google.maps &&
      initialInputValue === ""
    ) {
      setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
    }
  }, [isLoaded]);

  useEffect(() => {
    if (debouncedInput && sessionToken) {
      fetchPlaces(debouncedInput);
    }
  }, [debouncedInput, sessionToken]);

  const fetchPlaces = (input) => {
    if (window.google && window.google.maps) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input,
          sessionToken,
          language: "de",
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPlaces(predictions || []);
            setIsDropdownOpen(true);
          } else {
            setPlaces([]);
            setIsDropdownOpen(false);
          }
        }
      );
    }
  };

  const handleInputChange = (event) => {
    if (sessionToken === null) {
      setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
    }
    setInputValue(event.target.value);
    if (event.target.value.trim() === "") {
      setPlaces([]);
      setIsDropdownOpen(false);
    }
  };

  const handlePlaceSelect = (place) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { placeId: place.place_id, language: "de" },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          const location = results[0].geometry.location;
          const formatted_address = results[0].formatted_address;

          setMarker({
            latitude: location.lat(),
            longitude: location.lng(),
          });
          setViewport({
            ...viewport,
            latitude: location.lat(),
            longitude: location.lng(),
            zoom: 15,
          });

          let street = "";
          let street_number = "";
          let city = "";
          let country = "";
          let zipcode = "";

          results[0].address_components.forEach((component) => {
            const types = component.types;
            if (types.includes("street_number")) {
              street_number = component.long_name;
            }
            if (types.includes("route")) {
              street = component.long_name;
            }
            if (types.includes("locality")) {
              city = component.long_name;
            }
            if (types.includes("country")) {
              country = component.short_name;
            }
            if (types.includes("postal_code")) {
              zipcode = component.long_name;
            }
          });

          onChange({
            latitude: location.lat(),
            longitude: location.lng(),
            street,
            street_number,
            zipcode,
            city,
            country,
            formatted_address,
          });

          setInputValue(formatted_address);
          setPlaces([]);
          setIsDropdownOpen(false);
          setSessionToken(null);
        }
      }
    );
  };

  const onMarkerDragEnd = (event) => {
    const [longitude, latitude] = event.lngLat;
    setMarker({ longitude, latitude });
    onChange({ longitude, latitude });
  };

  const handleClick = (e) => {
    const [longitude, latitude] = e.lngLat;
    setMarker({ longitude, latitude });
    onChange({ longitude, latitude });
  };

  return (
    <Box>
      {isLoaded ? (
        <>
          <Box my={2}>
            <Alert severity="info">
              {t(
                "Enter the full delivery address and adjust the pin if necessary."
              )}
            </Alert>
          </Box>
          <FormControl fullWidth margin="none" style={{ position: "relative" }}>
            <TextField
              label={t("Adresse")}
              variant="outlined"
              fullWidth
              placeholder={t("Enter Address")}
              value={inputValue}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlinedIcon style={{ color: "#666" }} />
                  </InputAdornment>
                ),
              }}
            />
            {isDropdownOpen && places.length > 0 && (
              <List
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  backgroundColor: "#fff",
                  zIndex: 10,
                  border: "1px solid #ddd",
                }}
              >
                {places.map((place, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => handlePlaceSelect(place)}
                    style={{
                      padding: "1px 13px",
                      cursor: "pointer",
                      borderBottom:
                        index !== places.length - 1 ? "1px solid #ddd" : "none",
                    }}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: "40px",
                      }}
                    >
                      <PlaceOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <strong>{place.structured_formatting.main_text}</strong>
                      }
                      secondary={place.description}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </FormControl>
        </>
      ) : (
        <div>Loading...</div>
      )}
      <ReactMapGL
        width={"100%"}
        height={"500px"}
        {...viewport}
        onViewportChange={setViewport}
        mapStyle={MAPBOX_MAP_STYLE}
        mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
        onClick={handleClick}
      >
        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
          offsetTop={-20}
          offsetLeft={-10}
          draggable
          onDragEnd={onMarkerDragEnd}
        >
          <Pin size={30} />
        </Marker>
        <div
          style={{ position: "absolute", top: "0", left: 0, padding: "10px" }}
        >
          <NavigationControl />
        </div>
      </ReactMapGL>
    </Box>
  );
};

export default SelectGeoCoordinates;
